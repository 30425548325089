import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
class About extends React.Component {
    render() {
        return ( <Layout>
            <SEO title="About" keywords={[`gatsby`, `application`, `react`]} />
                <div className="site-About">
                    <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h1>Công ty Tân Tiến Thành - vải Tiến Sương</h1>
                            {/* <p>Ecommerce starter: <a href="https://www.gatsbyjs.org/">Gatsby</a> + <a href="https://flotiq.com">Flotiq</a> + <a href="https://snipcart.com">Snipcart</a>.</p>
                            <p>Live Demo: <a href="https://flotiq-starter-products.herokuapp.com">https://flotiq-starter-products.herokuapp.com</a></p> */}
                            <p>Chuyên kinh doanh sĩ và lẻ các mặt hàng vải</p>

                            <ul>
                                <li>
                                    <p>thời trang nữ: <strong>tuyết mưa, tuyết tăm, carolan, lụa, cát giấy... </strong> </p>
                                </li>
                                <li>
                                <p>thời trang nam: <strong>kaky, fo, gan,... </strong> </p>
                                </li>
                                <li>
                                <p> <strong>vải kaky, kate các loại </strong> </p>
                                </li>
                            </ul>

                            <h2 id="learningflotiq">Liên Hệ</h2>

        
                            <ul>
                                <li>
                                    <p>Email: <a href="mailto:congytantienthanh@gmail.com">congtytantienthanh@gmail.com</a></p>
                                </li>
                                <li>
                                    <p>Phone: <a href="tel:+842838644600">(+84)2838.644.600</a>&nbsp;&nbsp;&nbsp;
                                            <a href="tel:+84905933683">(+84)905.933.683</a>&nbsp;&nbsp;&nbsp;
                                            <a href="tel:+84782451474">(+84)782.451.474</a>&nbsp;&nbsp;&nbsp;
                                            <a href="tel:+84918913060">(+84)918.913.060</a>&nbsp;&nbsp;&nbsp;
                                    </p>
                                </li>
                                <li>
                                    <p>Address: <a href="https://tienthanhtextile.com">Số 2, đường số 2, phường 10, quận Tân Bình, Tp.HCM</a></p>
                                </li>
                                <li>
                                    <p>Công ty TNHH MTV XD SX TM DV TÂN TIẾN THÀNH – <strong>MST: 0306708590</strong></p>
                                </li>
                            </ul>

                            {/* <h2 id="deploy">Deploy</h2>
                            <p>You can deploy this project to Heroku in 3 minutes:</p>
                            <p><a href="https://heroku.com/deploy?template=https://github.com/flotiq/gatsby-starter-products"><img src="https://www.herokucdn.com/deploy/button.svg" alt="Deploy" /></a></p> */}
                        </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}
export default About